import '../styles/home2.css'
import LoadingScreen from '../components/loadingScreen';
import brandwork from '../resources/images/brandwork_edited.jpg'
import BottomContent from '../components/bottomContent';
import Carousel from '../components/carousel';
import camera from '../resources/images/Camera Vector-01.svg';
import { Link } from 'react-router-dom';

function Home() {
 
  return (
    <div className="container">
      <LoadingScreen />
    <div className="Background">
      <div className="b-image">

      </div>
      <div className="CreativeHead">
      <h1 className="CreativeText">I M. A CREATIVE.</h1>
      </div>
    </div>
    <div className="content">
    <div className='PortfolioSlideShow'>
        <div className="IntroVideoContainer">
        <video src="/videos/BACKGROUND_VIDEO_C.mp4" autoPlay loop muted />
         
        </div>
        <span><strong>MASON AYRES CREATIVE:</strong><br></br> YOUR CREATIVE PARTNER IN GRAPHIC DESIGN, PHOTOGRAPHY & VIDEOGRAPHY.</span>
      </div>
      <div className="backgroundColor">
      <div className='identitySection'>
          <div className="brandTitle">
            <h2><span className='brand'>YOUR BRAND</span></h2>
            <span className='identity'>YOUR IDENTITY, IN A NUTSHELL.</span>
          </div>
          <img src={brandwork} alt="brandwork.jpg"></img>
          <div className='descriptionSection'>
            <p className="description">Whether it's the brand of a large business, a solo entrepreneur's startup, or a personal brand, I can help you with laying out the bigger picture. <strong>(Or the small details.)</strong></p> 
            <p className="descriptionSmall">It could be brand visuals, a logo, full brand expressions, or just a consultation to see where your brand can be improved. I want to help you amplify your visibility, earn recognition, and become the go-to choice.</p>
          </div>
        </div>
        <Carousel></Carousel>
        <div className='videographySection'>
          <div className='videographyHeader'>
            <span>VIDEOGRAPY & PHOTOGRAPHY</span>
          </div>
        </div>
        <div class='featured'><iframe src='https://www.youtube.com/embed/RQUw1GI__0E' title='Youtube Video'></iframe></div>
        <img src={camera} alt="camera-vector" className='camera'></img>
        <h2 className="PhilHeader">MY PHILOSOPHY:</h2>
        <h2 className="ChaseHeader">CHASE ADVENTURE, CAPTURE THE STORY</h2>
        <div className="PhilDesc">
          <p className='PhilBody'>There’s a lot of moments to miss out there. My goal when it comes to photos/video is to
            capture the moment as it feels, not just as it looks. Seeking out opportunities to capture
            unique moments is what I gravitate towards.</p>
            <p>Visit the <Link to="/photography" target="_top">Photography Page</Link> to see more!</p>
        </div>
        <div className='exposition'>
          <p><strong>Well, you're down this far.</strong></p> <p>If you want a creative partner who isn't afraid to break molds and try something new for your brand or business, you've found a pretty solid option to get shit done, and have fun doing it. </p> <p>Plus, we might bring cookies.</p>
        </div>
    </div>
    <div className="Bottom">
      <BottomContent />
    </div>
    </div>
    
    </div>
  );
};

export default Home;