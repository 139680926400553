import '../styles/carousel.css';
import React, { useEffect, useState, useCallback } from 'react';
import imageUrlsData from '../resources/companyLinks.json';

const images = require.context('../resources/companyLogos/flat_svgs', false, /\.(png|jpe?g|svg)$/);
const imageList = images.keys().map(image => images(image));
const imageUrls = [...imageUrlsData, ...imageUrlsData];

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [disableTransition, setDisableTransition] = useState(false);
    const [divisor, setDivisor] = useState(window.innerWidth <= 1080 ? 1 : 3);

    const nextSlide = useCallback(() => {
        let nextIndex = currentIndex === imageList.length ? 0 : currentIndex + 1;
        if (nextIndex === 0) {
            setDisableTransition(true);
            setCurrentIndex(0);
            setTimeout(() => {
                setDisableTransition(false);
                setCurrentIndex(1);
            }, 10);
        } else {
            setCurrentIndex(nextIndex);
        }
    }, [currentIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(interval);
    }, [currentIndex, nextSlide]);

    const prevSlide = () => {
        let prevIndex = currentIndex === 0 ? imageList.length - 1 : currentIndex - 1;
        if (prevIndex === imageList.length - 1) {
            setDisableTransition(true);
            setCurrentIndex(imageList.length);
            setTimeout(() => {
                setDisableTransition(false);
                setCurrentIndex(imageList.length - 1);
            }, 10);
        } else {
            setCurrentIndex(prevIndex);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const newDivisor = window.innerWidth <= 1080 ? 1 : 3;
            setDivisor(newDivisor);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="carousel">
            <button className="prev" onClick={prevSlide}>&#10094;</button>
            <div className="slider" 
                 style={{ transform: `translateX(-${currentIndex * (100 / divisor)}%)`, 
                          transition: disableTransition ? 'none' : 'transform 0.5s ease' }}>
                {[...imageList, ...imageList].map((image, index) => (
                    <a key={index} href={imageUrls[index]} target="_blank" rel="noopener noreferrer"><img src={image} alt={`Slide ${index}`} /></a>
                ))}
            </div>
            <button className="next" onClick={nextSlide}>&#10095;</button>
        </div>
    );
};

export default Carousel;