import '../styles/standard.css';
import { Helmet } from 'react-helmet';

function Animation(){
    return(
        <div className="Animation">
            <Helmet>
                <title>Animation & Motion Graphics | Mason Ayres Creative</title>
            </Helmet>
            <div className='content1'>
                <header>
                    <h1>CUSTOM ANIMATION</h1>
                    <p>Page under construction. Be here soon!</p>
                </header>
                <section>
                
                </section>
            </div>
        </div>
    ); 
};

export default Animation;