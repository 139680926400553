import React, { useState, useEffect } from 'react';
import '../styles/photography.css';
import '../styles/HeroGallery.css'
/* Images for Hero Gallery */
import HeroImage1 from '../resources/images/portraits/Bianca_GradPhotos-30.jpg';
import HeroImage2 from '../resources/images/portraits/Personals064.jpg';
import HeroImage3 from '../resources/images/events/TZ7_4108.jpg';
import HeroImage4 from '../resources/images/events/TZ7_4285.jpg';
import HeroImage5 from '../resources/images/events/CD_Pageant.jpg';
import HeroImage6 from '../resources/images/products/VanReedFood_Compressed-32.jpg';
import HeroImage7 from '../resources/images/products/RCC_Menu-02.jpg';

const heroImages = [HeroImage1, HeroImage2, HeroImage3, HeroImage4, HeroImage5, HeroImage6, HeroImage7];

const HeroGallery = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Change the hero image every 5 seconds
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
    }, 5000);

    return () => clearInterval(interval); // Clean up on component unmount
  }, []);

  return (
    <div className="hero-image-container">
      {heroImages.map((image, index) => (
        <div
          key={index}
          className={`hero-image ${index === currentImageIndex ? 'visible' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
        />
      ))}
    </div>
  );
};

export default HeroGallery;