import React from 'react';
import '../styles/hamburgerButton.css';
import hamburgerButton from '../resources/HamburgerButton.svg';

function HamburgerButton() {
    return (
        <div className='hamburgerMenu'>
            <img src={ hamburgerButton } alt='Hamburger Button' />
        </div>
    );
}

export default HamburgerButton;
