import NavBar from './navBar';
function Layout({ children }) {
    return (
        <div>
            <NavBar />
            { children }
        </div>
    );
}

export default Layout