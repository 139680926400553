import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4';
import App from './App';
import './index.css';

const TRACKING_ID = process.env.REACT_APP_REACT_GA_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);
console.log("GA initialized with tracking ID:", TRACKING_ID);
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);