import '../styles/standard.css';
import {Helmet} from 'react-helmet';

function PrintServices(){
    return(
    <div className="PrintServices">
        <Helmet>
            <title>Print Services | Mason Ayres Creative</title>
            <meta name="description" content="Making it easier for you to get the printing results you're looking for - custom business cards, flyers, stickers, booklets and more, exactly the way you want them with no bull."/>
        </Helmet>
        <div className='content1'>
            <header>
                <h1>Custom Print Materials in Berks County, PA</h1>
                <p>Page under construction. Be here soon!</p>
            </header>
        </div>
    </div>  
    );
};

export default PrintServices;