import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import BottomContent from '../components/bottomContent';
import ContactForm from '../components/contactForm';
import '../styles/contact.css';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={ captchaKey }>
      <div className="ContactPage">
        <Helmet>
          <title>Contact | Mason Ayres Creative</title>
          <meta name="description" content="So, you want to get in touch with me, huh? I'm flattered... Reach out!"/>
          <meta property="og:description" content="So, you want to get in touch with me, huh? I'm flattered... Reach out!"/>
        </Helmet>
        <div className="showuppls">
          <h1 className="ContactHeader">CONTACT MASON AYRES CREATIVE</h1>
          <div className="ContactDesc">
            <p>Reach out with your questions/inquiries about graphic design, photography & videography here!</p>
          </div>
        </div>
        <div className="formContainer">
          <ContactForm />
        </div>
        <BottomContent />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default Contact;
