import './App.css';
import Layout from './components/layout';
import Home from './pages/home';
import GraphicDesign from './pages/graphicDesign';
import Photography from './pages/photography';
import Videography from './pages/videography';
import PrivacyPolicy from './pages/privacypolicy';
import About from './pages/about';
import Contact from './pages/contact';
import PrintServices from './pages/printservices';
import Branding from './pages/branding';
import WebDesign from './pages/webdesign';
import Animation from './pages/animation';
import PageLayout from './pages/pagelayout';
import PageNotFound from './pages/pageNotFound';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import Illustration from './pages/illustration';

function App() {
  const location = useLocation();

  useEffect(() => {
    // Use ReactGA.send() for GA4
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return (
    <div className="App">
      <Helmet></Helmet>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="graphic-design" element={<GraphicDesign />} />
          <Route path="photography" element={<Photography />} />
          <Route path="videography" element={<Videography />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="printservices" element={<PrintServices />} />
          <Route path="webdesign" element={<WebDesign />} />
          <Route path="branding" element={<Branding />} />
          <Route path="animation" element={<Animation />} />
          <Route path="pagelayout" element={<PageLayout />} />
          <Route path="illustration" element={<Illustration />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;