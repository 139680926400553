import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            console.log('Execute reCAPTCHA not yet available');
            return null;
        }

        try {
            const token = await executeRecaptcha('contactForm');
            console.log('Verified token');
            return token;
        } catch (error) {
            console.error('Error executing reCAPTCHA:', error);
            return null;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const email = formData.get('email');
        const emailUrl = process.env.REACT_APP_EMAIL_URL;

        // Basic email format validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            alert('Invalid email format.');
            return;
        }

        const token = await handleReCaptchaVerify();
        if (!token) {
            alert('reCAPTCHA verification failed. Please try again.');
            return;
        }

        formData.append('recaptchaToken', token);

        try {
            const response = await fetch(emailUrl, {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();

            if (result.status === 'success') {
                alert('Your message has been sent!');
                event.target.reset();
            } else {
                alert('Failed to send message. ' + result.message);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Failed to send message. Please try again later.');
        }
    };

    return (
        <form onSubmit={ handleSubmit }>
            <div className="formRow">
                <div className="formLeft">
                    <label htmlFor="name">NAME</label>
                    <input type="text" id="name" name="name" required />
                    <label htmlFor="email">EMAIL</label>
                    <input type="email" id="email" name="email" required />
                    <label htmlFor="subject">SUBJECT</label>
                    <input type="text" id="subject" name="subject" />
                </div>
                <div className="formRight">
                    <label htmlFor="message">YOUR MESSAGE</label>
                    <textarea id="message" name="message" required></textarea>
                </div>
            </div>
            <button type="submit">SUBMIT</button>
        </form>
    );
};

export default ContactForm;