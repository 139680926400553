import '../styles/standard.css';
import { Helmet } from 'react-helmet';
function PageLayout(){
    return(
        <div className="PageLayout">
            <Helmet>
                <title>Page Layout Services | Mason Ayres Creative</title>
                <meta name="description" content="Producing quality custom brochures, flyers, booklets, covers, and more- with excellent client communication at an affordable price."/>
                <meta property="og:description" content="Producing quality custom brochures, flyers, booklets, covers, and more- with excellent client communication at an affordable price."/>
                <meta name="keywords"content="page layout, book covers, graphic design, flyers, brochures, pamphlets, custom layout design, berks county, pennsylvania"/>
                <meta property="og:title" content="Unbelievable Page Layout Services"/>
            </Helmet>
            <div className='content1'>
                <header>
                    <h1>Page Layout Services in Berks County, PA</h1>
                    <p>Page under construction. Be here soon!</p>
                </header>
                <section>
                    
                </section>
            </div>
        </div>
    );
};

export default PageLayout;