import '../styles/loadingScreen.css'
import React, { useState, useEffect } from "react";

const LoadingScreen = () => {
  const [isVisible, setIsVisible] = useState(true);
  const lottieLoadFile = process.env.REACT_APP_LOTTIE_LOAD_FILE;

  useEffect(() => {
    // Simulate waiting for animation to complete (adjust timeout as needed)
    const animationDuration = 3500; // 5 seconds
    setTimeout(() => {
      setIsVisible(false);
    }, animationDuration);
  }, []);

  return (
    <>
      {isVisible && (
        <div className="loading-screen">
          <lottie-player
            src={lottieLoadFile}
            background="transparent"
            speed="1"
            autoplay
            width="100%"
            height="auto"
          ></lottie-player>
        </div>
      )}
    </>
  );
};

export default LoadingScreen;