import React, { useState, useRef, useEffect } from 'react';
import '../styles/faqstyle.css'; // Import the CSS file

const FAQMenu = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [contentHeight, setContentHeight] = useState('0px');
  const contentRefs = useRef([]);

  useEffect(() => {
    if (activeIndex !== null) {
      // Add 20 pixels to the calculated height
      setContentHeight(`${contentRefs.current[activeIndex].scrollHeight + 40}px`);
    } else {
      setContentHeight('0px');
    }
  }, [activeIndex]);

  const toggle = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  // Define the current year
  const currentYear = new Date().getFullYear();

  const faqs = [
    { 
      question: "What is your availability like for design projects?", 
      answer: "Generally speaking, the type of project will dictate how long of a wait you can expect to be scheduled out for. Large projects such as a comprehensive brand strategy, or a full website design and deployment may be scheduled out a month or two in advance depending on availability."
    },
    { 
      question: "Do you offer custom packages for work not listed in your pricing guide?", 
      answer: "You must have something really crazy you wanna get done, huh? Of course. Whether it's custom creative work like painting your entire building with goofy cartoons, recreating a cherished family photo album but photoshopping everyone's heads bigger, or creating your own hit TV show about a panda that starts his own restaurant, we'll assemble the talent and resources to help you get done what you're aiming to do, and price it out accordingly."
    },
    { 
      question: "Do you travel for shoots? How far?", 
      answer: "As far as it takes - to the edges of the earth of course. Though travel restrictions and fees may apply for jobs that are out of our service area (outside of southeastern PA, for example)."
    },
    { 
      question: "Do you do creative consultations?", 
      answer: "Absolutely! And I would recommend it, regardless of who you go with for your creative services. For creative work, communication is key when attempting to achieve a goal, with brand strategy for example. Discovery calls are always free, and strategy consultations are a great way to spearhead new pathways to success."
    },
    { 
      question: "Is it just you doing the work?", 
      answer: "<strong>No way</strong> - I think I would explode. I have a large network of creative powerhouses I work together with to get the job done, including other brand strategists, artists, photographers, and videographers."
    },
    { 
      question: "Why does your business do so many things?", 
      answer: "To put it simply: because I want to. I get bored easily, so taking on new ways to help people out is always exciting and fulfilling. Plus, a lot of creative work has a decent amount of overlap, and it's a lot more helpful to people to handle several aspects of the process rather than excelling at one niche skillset."
    },
    { 
      question: "Does Mason Ayres Creative have Retainer Packages?", 
      answer: "Depending on what it is, it's not only appropriate but necessary to have a creative partner who can help you with regular work directed towards your goals. Retainers are great for this! If you're interested, send us a message so we can attend to your needs properly."
    },
    { 
      question: "How are your prices?", 
      answer: `A big value of my company is transparency above all else. That's why I include a yearly pricing guide to give you a good idea of what kind of pricing you'd be looking at! However, some things can't be priced out without a proper consultation and evaluation. After all, a <strong>$20,000</strong> website is going to cost <strong>$20,000</strong>. If your needs are big, the price tag will be too, but I strive to make sure they're fair, appropriate and give you more than the value you're looking for. Check out the section below for the <strong>${currentYear}</strong> pricing guide!`
    },
    // Add more FAQs here
  ];

  return (
    <div className="faq-menu">
      <h1>FAQ</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggle(index)}>
            {faq.question}
            <span className={`arrow ${activeIndex === index ? 'open' : ''}`}>▼</span>
          </div>
          <div
            ref={el => contentRefs.current[index] = el}
            className={`faq-answer ${activeIndex === index ? 'open' : ''}`}
            style={{ maxHeight: activeIndex === index ? contentHeight : '0px' }}
            dangerouslySetInnerHTML={{ __html: faq.answer }} // Inject HTML content
          />
        </div>
      ))}
    </div>
  );
};

export default FAQMenu;
