import '../styles/pageNotFound.css'
import guy404 from '../resources/404guy2.svg';

function PageNotFound() {
    return(
        <div className='content404'>
            <div className='text404'>
                <h1>OH SNAP!!!</h1>
                <h2>404: PAGE NOT FOUND</h2>
                <a href="https://www.masonayres.design">BACK TO HOME</a>
            </div>
            <div className='guy404'>
                <img src={guy404} alt="404 drawing"></img>
            </div>
        </div>
    )
}

export default PageNotFound;