import '../styles/standard.css';
import { Helmet } from 'react-helmet';

function Branding(){
    return(
        <div className="Branding">
            <Helmet>
                <title>Mind-Blowing Custom Branding Solutions | Mason Ayres Creative</title>
            </Helmet>
            <div className='content1'>
                <header>
                    <h1>CUSTOM BRANDING IN BERKS COUNTY, PA</h1>
                    <p>Page under construction. Be here soon!</p>
                </header>
                <section>

                </section>   
            </div>
        </div>
    );
};

export default Branding;