import '../styles/standard.css';
import { Helmet } from 'react-helmet';

function Illustration(){
    return(
        <div className="Illustration">
            <Helmet>
                <title>Custom Illustration | Mason Ayres Creative</title>
            </Helmet>
            <div className='content1'>
                <header>
                    <h1>CUSTOM ILLUSTRATION IN BERKS COUNTY, PA</h1>
                    <p>Page under construction. Be here soon!</p>
                    <a href="https://aardboi.redbubble.com">REDBUBBLE LINK</a>
                </header>
                <section>

                </section>   
            </div>
        </div>
    );   
};

export default Illustration;