import '../styles/bottomContent.css';
import fbIcon from '../resources/socialMediaIcons/fbIcon.svg';
import instaIcon from '../resources/socialMediaIcons/instaIcon.svg';
import linkedInIcon from '../resources/socialMediaIcons/linkedInIcon.svg';
import ytIcon from '../resources/socialMediaIcons/ytIcon.svg';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

function Typewriter() {
  const [isTyping, setIsTyping] = useState(false);
  const [hasLoaded, setHadLoaded] = useState(false);
  const [isBlinking, setIsBlinking] = useState(false);
  const [ref, inView] = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView && !hasLoaded) {
      setIsTyping(true);
      setHadLoaded(true);
    }
  }, [inView, hasLoaded]);

  useEffect(() => {
    if (isTyping) {
      const typingTimeoutId = setTimeout(() => {
        setIsTyping(false);
        setIsBlinking(true);
      }, 3000);
      return () => clearTimeout(typingTimeoutId);
    }
  }, [isTyping]);

  useEffect(() => {
    if (!isTyping && isBlinking) {
      const blinkingTimeoutId = setTimeout(() => {
        setIsBlinking(false);
      }, 4000);
      return () => clearTimeout(blinkingTimeoutId);
    }
  }, [isTyping, isBlinking])

  return (
    <div className="typewriter" ref={ref}>
      <p id="typewriter-text" className={`${isTyping ? 'typing' : ''}${isBlinking ? 'blinking' : ''}`}>
        If you're ready, let's get rolling!
      </p>
    </div>
  );
}

function LottieAnimation() {
  const [isInView, setIsInView] = useState(false);
  const [hasLoaded, setHadLoaded] = useState(false);
  const [ref, inView] = useInView({ threshold: 0.5 });
  const lottieFile = process.env.REACT_APP_LOTTIE_FILE;
  
  useEffect(() => {
    if (inView && !hasLoaded) {
      setIsInView(true);
      setHadLoaded(true)
    }
  }, [inView, hasLoaded]);

  return (
    <div className="lottie-parent" ref={ref} role='banner'>
      {isInView && (
        <lottie-player
          loading='lazy'
          src={lottieFile}
          background='transparent'
          speed='.9'
          autoplay
        ></lottie-player>
      )}
    </div>
  );
}

function BottomContent() {
  return (
    <div className='bottomParent'>
        <Typewriter />
        <LottieAnimation />
        <div className="text-container">
            <span className='no-stroke'>GET IN TOUCH WITH ME.</span>
            <span className='stroke'>GET IN TOUCH WITH ME.</span>
        </div>
        <div className='buttonContainer'>
          <a href='/' className='buttonLink'><div className='buttonDiv'><p>LET'S TALK!</p></div></a>
        </div>
        <div className='socials'>
            <a className="socialLink" href="https://www.youtube.com/channel/UC-G1dIPyV8btWv3I2emg_zw"><img className='socialIcon' src={ytIcon} alt='YouTube Logo'></img></a>
            <a className="socialLink" href="https://www.instagram.com/msnayrescreative/"><img className='socialIcon' src={instaIcon} alt='Instagram Logo'></img></a>
            <a className="socialLink" href="https://www.facebook.com/masonayresdesign/"><img className='socialIcon' src={fbIcon} alt='Facebook Logo'></img></a>
            <a className="socialLink" href="https://www.linkedin.com/company/mason-ayres-creative/"><img className='socialIcon' src={linkedInIcon} alt='LinkedIn Logo'></img></a>
        </div>
        <div className='footer'>
          <a className="footerlinks" href="./privacypolicy">PRIVACY POLICY</a> 
          <a className="footerlinks" href="./contact">CONTACT</a> 
          <a className="footerlinks" href="https://www.masonayres.design/deprecated" target='_blank' rel='noreferrer'>LEGACY SITE</a>
          <a className="footerlinks" href="https://www.masonayres.design/" target='_blank' rel='noreferrer'>SITE MAP</a>  
        </div>
    </div>
  );
}

export default BottomContent;